import React from "react"
import { List, Datagrid, TextField } from "react-admin"
import { observer, inject } from "mobx-react"
import styled from "styled-components"

const FormContainer = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 20px;
`

const RoleList = ({ userStore, ...props }) => {
  return (
    <FormContainer>
      {userStore.org && (
        <List {...props} bulkActionButtons={false} >
          <Datagrid>
            <TextField source='name' label='customRoot.roles.name' sortable={false} />
            <TextField source='description.he' label='customRoot.roles.description' sortable={false} />
          </Datagrid>
        </List>
      )}
    </FormContainer>
  )
}

export default inject("userStore")(observer(RoleList))
