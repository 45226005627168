import React from "react"
import { List, Pagination, Datagrid, TextField, FunctionField } from "react-admin"
import * as moment from 'moment'
import JobFilter from "./jobFilter"
import styled from "styled-components"

const ListContainer = styled.div`
  margin-top: 20px;
`

const PostPagination = props => <Pagination rowsPerPageOptions={[50, 100]} {...props} />;

const JobStatus = ({ status }) => {
  
  const statusList = {
    pending: '#A9A9A9',
    in_progress: '#4682B4',
    finished: '#006400',
    failed: '#A52A2A' 
  } 

  return (
    <span style={{ color: statusList[status] }}>
        {`${status}`.replace('_', ' ').toUpperCase()}
    </span> 
  )
}

const JobList = props => {
  return (
    <ListContainer>
    <List {...props} filters={<JobFilter />}  pagination={<PostPagination />} perPage={100} exporter={false}  bulkActionButtons={false}>
      <Datagrid rowClick='show'>
        <TextField source='id' label='customRoot.jobs.list.id' sortable={false} />
        <FunctionField label="customRoot.jobs.list.type" render={record => `${record.type.description}`} />
        <FunctionField label="customRoot.jobs.list.scope" render={record => `${record.scope.name}`} />
        <FunctionField label='customRoot.jobs.list.input_data' render={record => record.organization.code} sortable={false} />      
        <FunctionField label="customRoot.jobs.list.status" render={record => <JobStatus status={record.status.name} />} />
        <FunctionField label='customRoot.jobs.list.created_at' render={record => moment(record.createdAt).format('DD/MM/YYYY hh:mm:ss')} sortable={false} />
      </Datagrid>
    </List>
    </ListContainer>
  )
}

export default JobList
